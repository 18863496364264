const Brand = {
    name: "Lianeli",
    description: "Mon dépistage personnalisé"
}

const Colors = {
    // primary
    primary: "#00426D",
    primaryDark: "#003557",
    primaryLight: "#5688A7",
    // secondary
    secondary: "#0896B5",
    secondaryDark: "#067891",
    secondaryLight: "#A4DCE8",
    secondaryExtraLight: "#D1F0F7",
    // corail
    corail: "#FF7061",
    corailDark: "#D75B4E",
    corailLight: "#FFD4D0",
    // yellow
    yellow: "#F2CF1C",
    yellowDark: "#C2A616",
    yellowLight: "#FBF1BB",
    // green
    green: "#87C2A8",
    greenDark: "#6EA08A",
    greenLight: "#DBEDE5",
    // danger
    danger: "#FF9595",
    dangerDark: "#FF4E4E",
    dangerLight: "#FFDCDC",
    // success
    success: "#91DDC2",
    successDark: "#47C799",
    successLight: "#DAF4EB",
    // warning
    warning: "#FFCF87",
    warningDark: "#FFAF37",
    warningLight: "#FFEFD7",
    // grey
    grey: "#556671",
    greyDark: "#1A2328",
    greyLight: "#F0F2F3",
    // default
    default: "#003557",
    white: "#fafbfb",
}

const Theme = {
    primaryColor: Colors.primary,
    secondaryColor: Colors.secondary,
    errorColor:  Colors.danger,
    warningColor:  Colors.$warning,
    successColor:  Colors.success,
    infoColor:  Colors.secondaryLight,
}

module.exports = {Theme, Colors, Brand}