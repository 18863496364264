import React, { createContext, useReducer } from 'react';
import { UserReducer } from './userReducer';

export interface UserInterface {
    id: string,
    username: string,
    firstname: string,
    lastname: string,
    fullname: string,
    email: string,
    telephone: true,
    createdAt: string,
    patient: any,
    doctor: any,
    notifications: string,
    logConnexions: any,
    sharedInvitations: any,
    blocked: boolean,
    confirmed: boolean,
    isAuthorized: boolean,
    role: {
        id: number,
        name: string
    },
    updatedAt: string,
}

export interface StoreInterface {
    userInfos?: UserInterface,
    jwtToken?: string,
    startSession: string|undefined,
    isAuthorized: boolean,
}

interface Props {
    children: React.ReactNode;
}

interface CtxValues {
    userStore: StoreInterface;
    dispatch: React.Dispatch<any>;
}

const initialState: StoreInterface = {
    userInfos: undefined,
    jwtToken: undefined,
    startSession: undefined,
    isAuthorized: false
}

const UserContext = createContext<CtxValues>({ userStore: initialState, dispatch: () => null })

const UserProvider: any = ({ children }: Props) => {
    const [userStore, dispatch] = useReducer(UserReducer, initialState);
    return (
        <UserContext.Provider value={{ userStore, dispatch }}>
            {children}
        </UserContext.Provider>
    )
}

const ActionTypes = {
    login: 'Login',
    logout: 'Logout',
    userLoaded: 'UserLoaded',
    authConfirm: 'AuthConfirm',
    updateUserData: 'updateUserData',
};

export { UserContext, UserProvider, ActionTypes }
