/**
 * LOGS CONNEXION REQUESTS
 */

import { LOG_CONNEXION_URL } from '../apiUrl'
import Axios from './axiosSetup';

/**
 * Log Connexion Request
 * @param formData 
 * @returns 
 */
export const SaveLogSession = (formData: object) => {
  Axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('jwtToken')}`;
  return Axios.post(`${LOG_CONNEXION_URL}`, formData);
}
