/**
 * AUTH REQUESTS
 */
import { LOGIN_URL, ME_URL, EMAIL_CONFIRMATION, CHECK_RESET_TOKEN, FORGOT_PASSWORD, RESET_PASSWORD, SEND_AUTH_CODE, CHECK_AUTH_CODE } from '../apiUrl'
import Axios from './axiosSetup';

/**
 * Login patient Request
 * @param {identifier, password} formData 
 * @returns 
 */
export const LoginRequest = async (formData: { identifier: string; password: string; }) => {
  if(sessionStorage.getItem('jwtToken') !== null) {
    Axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('jwtToken')}`;
  }else{
    delete Axios.defaults.headers.common.Authorization
  }
  const { identifier, password } = formData
  return Axios.post(LOGIN_URL, { identifier, password });
}

/**
 * Get User By Token 
 * @returns 
 */
export function GetMe() {
  if(sessionStorage.getItem('jwtToken') !== null) {
    Axios.defaults.headers.common.Authorization = `Bearer ${sessionStorage.getItem('jwtToken')}`;
  }
  return Axios.get(ME_URL);
}

/**
 * Get email confirmation by token
 * @returns 
 */
export function GetEmailConfirmation(token: string) {
  return Axios.get(`${EMAIL_CONFIRMATION}?confirmation=${token}`);
}

/**
 * Get email confirmation by token
 * @returns 
 */
export function CheckResetToken(token: string) {
  return Axios.get(`${CHECK_RESET_TOKEN}?token=${token}`);
}

/**
 * Forgot Password Request
 * @param {email} formData 
 * @returns 
 */
export const ForgotPasswordRequest = async (formData: { email: string }) => {
  const { email } = formData
  return Axios.post(`${FORGOT_PASSWORD}`, { email });
}

/**
* Reset Password
* @param {PasswordProps, passwordConfirmation, code} formData 
* @returns 
*/
export function ResetPasswordRequest(formData: object) {
  return Axios.post(`${RESET_PASSWORD}`, formData);
}

/**
 * Send Auth code
 * @returns 
 */
export function SendAuthCode(email: string) {
  return Axios.post(`${SEND_AUTH_CODE}`, {email});
}

/**
 * Check Auth code
 * @returns 
 */
export function CheckAuthCode(code: string) {
  return Axios.get(`${CHECK_AUTH_CODE}?code=${code}`);
}